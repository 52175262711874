

export interface IUser {
  id: number
  name: string
}

export enum CountryCode {
  Ru = 'ru'
}


/*
English, French, German, Italian, Japanese, Portuguese, Simplified Chinese, Spanish, Korean, Arabic
 */
export const LangList = [
  {id: 'ru', name: 'Русский'},
  {id: 'en', name: 'Английский'},
  {id: 'ua', name: 'Украинский'},
  {id: 'es', name: 'Испанский'},
  {id: 'ka', name: 'Грузинский'},
  {id: 'hy', name: 'Армянский'},
  {id: 'kk', name: 'Казахский'},
  {id: 'pt', name: 'Португальский'},
  {id: 'de', name: 'Немецкий'},
  {id: 'fr', name: 'Французский'},
  {id: 'pl', name: 'Польский'},
  {id: 'et', name: 'Эстонский'},
  {id: 'lt', name: 'Литовский'},
  {id: 'lv', name: 'Латышский'},
]
